import React from "react";
import ReactModal from "react-modal";
import "./style.scss";

interface Props {
  isOpen: boolean;
  onClickClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<Props> = (props: Props) => {
  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={props.onClickClose}
      contentLabel="Example Modal"
    >
      {props.children}
    </ReactModal>
  );
};

export default Modal;
