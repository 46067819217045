import React from "react";
import PropTypes from "prop-types";
import ScrollToNext from "../../components/ScrollToNext";
import "./style.scss";
import { useThemeSwitcherContext } from "../../components/ThemeSwitcher";
import ExperienceItems from "./experiences-item";
import PastExperienceItem from "../../components/PastExperienceItem";

const AboutPage = () => {
  const {
    theme: { colorPrimary, colorHighlight, bgPrimary, textPrimary },
  } = useThemeSwitcherContext();

  return (
    <div className="experience-page" style={{ backgroundColor: bgPrimary }}>
      <style>
        {`
          .highlight {
            background-color: ${colorHighlight};
          }
          ::selection {
            background-color: ${colorHighlight};
          }
        `}
      </style>
      <div className="content-grid">
        <h1 style={{ color: colorPrimary }}>Past Experiences</h1>
        <div className="experience-wrapper">
          <div className="experience-content" style={{ color: textPrimary }}>
            {ExperienceItems.map((item, i) => (
              <PastExperienceItem item={item} key={i} />
            ))}
          </div>
        </div>
      </div>
      <ScrollToNext pageSelector=".portfolio-page" />
    </div>
  );
};

AboutPage.contextTypes = {
  theme: PropTypes.any,
};

export default AboutPage;
