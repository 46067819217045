import React from "react";
import IntroPage from "../pages/IntroPage";
import AboutPage from "../pages/AboutPage";
import PortfolioPage from "../pages/PortfolioPage";
import ParallaxBackground from "../components/ParallaxBackground";
import ScrollTop from "../components/ScrollTop";
import Footer from "../components/Footer";
import ExperiencePage from "../pages/ExperiencePage";

const LandingPage = () => {
  return (
    <div>
      <ParallaxBackground />
      <IntroPage />
      <AboutPage />
      <ExperiencePage />
      <PortfolioPage />
      <ScrollTop />
      <Footer />
    </div>
  );
};

export default LandingPage;
