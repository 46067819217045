import React from "react";
import PropTypes from "prop-types";
import ScrollToNext from "../../components/ScrollToNext";
import "./style.scss";
import { useThemeSwitcherContext } from "../../components/ThemeSwitcher";

const AboutPage = () => {
  const {
    theme: { colorPrimary, colorHighlight, bgPrimary, textPrimary },
  } = useThemeSwitcherContext();

  return (
    <div className="about-page" style={{ backgroundColor: bgPrimary }}>
      <style>
        {`
          .highlight {
            background-color: ${colorHighlight};
          }
          ::selection {
            background-color: ${colorHighlight};
          }
        `}
      </style>
      <div className="content-grid">
        <h1 style={{ color: colorPrimary }}>About</h1>
        <div className="about-wrapper">
          <div className="about-content" style={{ color: textPrimary }}>
            <p>
              I like <span className="highlight">JavaScript</span> and
              everything web (yeah no discrimination 1 / 2 / 3 all of it).
            </p>
            <p>
              When my dev senses kick-in I build presumably{" "}
              <span className="highlight"> awesome stuff</span>. I stay close to
              the community and try to keep tabs with the pace at which the web
              is evolving. I also like to{" "}
              <span className="highlight">blog</span> what I learn.
            </p>
            <p>
              I built this site <span className="highlight">from scratch</span>.
              By scratch, I mean <i>absolutely from scratch</i>{" "}
              <span className="highlight">
                without any UI library/framework
              </span>{" "}
              (except React though) and had so much fun along the way.
            </p>
            <p>
              React, Node.js, React-Native, JavaScript, Solidity, Web3, AWS and
              Firebase are the main tricks up my sleeve. I am also obsessed with
              making the web look pretty with SASS/CSS.
            </p>
            <p className="text-emoji" style={{ color: colorPrimary }}>
              \ (•◡•) /
            </p>
          </div>
        </div>
      </div>
      <ScrollToNext pageSelector=".portfolio-page" />
    </div>
  );
};

AboutPage.contextTypes = {
  theme: PropTypes.any,
};

export default AboutPage;
