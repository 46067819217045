const truncateText = (text: string) => {
  return text.length > 86 ? text.substring(0, 83) + "..." : text;
};

const PortfolioItems = [
  {
    name: "Cerebro",
    render: (
      <div className="portfolio-item">
        <div className="portfolio-item__title">Cerebro</div>
        <div className="portfolio-item__desc">Techfest Website</div>
        <div className="portfolio-item__icon">
          <i className="fab fa-react"></i>
          <i className="fab fa-css3"></i>
        </div>
        <div className="portfolio-item__links">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://cerebro.iiitvadodara.ac.in"
          >
            Cerebro
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/cerebro-iiitv/web-2018"
          >
            More
          </a>
        </div>
      </div>
    ),
  },
  {
    name: "Ongaku",
    render: (
      <div className="portfolio-item">
        <div className="portfolio-item__title">Ongaku</div>
        <div className="portfolio-item__desc">Anime radio player</div>
        <div className="portfolio-item__icon">
          <i className="fab fa-html5"></i>
          <i className="fab fa-css3"></i>
          <i className="fab fa-js"></i>
        </div>
        <div className="portfolio-item__links">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://ongaku.js.org"
          >
            Ongaku
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/anshumanv/ongaku"
          >
            More
          </a>
        </div>
      </div>
    ),
  },
  {
    name: "Luna",
    render: (
      <div className="portfolio-item">
        <div className="portfolio-item__title">Luna</div>
        <div className="portfolio-item__desc">
          Fully dynamic website for Event management system.
        </div>
        <div className="portfolio-item__icon">
          <i className="fab fa-php"></i>
          <i className="fab fa-js"></i>
          <i className="fab fa-css3"></i>
        </div>
        <div className="portfolio-item__links">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/DivyeshPuri/_Luna_"
          >
            More
          </a>
        </div>
      </div>
    ),
  },
  {
    name: "Spider",
    render: (
      <div className="portfolio-item">
        <div className="portfolio-item__title">Web Crawler</div>
        <div className="portfolio-item__desc">
          Crawls related websites and provides all the links.
        </div>
        <div className="portfolio-item__icon">
          <i className="fab fa-python"></i>
        </div>
        <div className="portfolio-item__links">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://drive.google.com/open?id=1dgfFEzCOxKssb-Xprnnn2FyNcOBFPCUB"
          >
            More
          </a>
        </div>
      </div>
    ),
  },
  {
    name: "Downloader",
    render: (
      <div className="portfolio-item">
        <div className="portfolio-item__title">Downloader</div>
        <div className="portfolio-item__desc">
          Python Script to Download everything a website has of particular
          format.
        </div>
        <div className="portfolio-item__icon">
          <i className="fab fa-python"></i>
        </div>
        <div className="portfolio-item__links">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://drive.google.com/open?id=1MJY_dUA94DK6cW1mU-RGAqZ8tqbZvt3s"
          >
            More
          </a>
        </div>
      </div>
    ),
  },
  {
    name: "Social Network",
    render: (
      <div className="portfolio-item">
        <div className="portfolio-item__title">Social Network</div>
        <div className="portfolio-item__desc">
          C implementation of all the function that facebook has.
        </div>
        <div className="portfolio-item__icon">
          <i className="fab fa-cuttlefish"></i>
        </div>
        <div className="portfolio-item__links">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/vanguard07/SocialNetwork"
          >
            More
          </a>
        </div>
      </div>
    ),
  },
  {
    name: "downasaur bot",
    render: (
      <div className="portfolio-item">
        <div className="portfolio-item__title">Downasaur Bot</div>
        <div className="portfolio-item__desc">
          AI Powered Bot for auto playing India's most accessible game.
        </div>
        <div className="portfolio-item__icon">
          <i className="fab fa-python"></i>
          <i className="fa-duotone fa-microchip-ai"></i>
        </div>
        <div className="portfolio-item__links">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/aashutoshrathi/downasaur-bot"
          >
            More
          </a>
        </div>
      </div>
    ),
  },
  {
    name: "Sahara",
    render: (
      <div className="portfolio-item">
        <div className="portfolio-item__title">Sahara</div>
        <div className="portfolio-item__desc">
          A transparent platform for supporting the less fortunate.
        </div>
        <div className="portfolio-item__icon">
          <i className="fab fa-react"></i>
          <i className="fab fa-ethereum"></i>
          <i className="fab fa-node"></i>
        </div>
        <div className="portfolio-item__links">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://amany9000.github.io/contactus"
          >
            Sahara
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/amany9000/Sahara"
          >
            More
          </a>
        </div>
      </div>
    ),
  },
  {
    name: "CrowdFunding",
    render: (
      <div className="portfolio-item">
        <div className="portfolio-item__title">CrowdFunding</div>
        <div className="portfolio-item__desc">
          {truncateText(
            "An ethereum based Crowdfunding Application. The main benefits provided by the DApp are trust, transparency and consensus."
          )}
        </div>
        <div className="portfolio-item__icon">
          <i className="fab fa-react"></i>
          <i className="fab fa-ethereum"></i>
          <i className="fab fa-node"></i>
        </div>
        <div className="portfolio-item__links">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://amany9000.github.io/eth-crowdFunding/"
          >
            CrowdFunding
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/amany9000/eth-crowdFunding"
          >
            More
          </a>
        </div>
      </div>
    ),
  },
  {
    name: "DIP",
    render: (
      <div className="portfolio-item">
        <div className="portfolio-item__title">DIP</div>
        <div className="portfolio-item__desc">
          {truncateText(
            " A use case intended for WIPO. The end product is a global Intellectual Property (IP), maintained on a DLT and not owned by any single Country or Company."
          )}
        </div>
        <div className="portfolio-item__icon">
          <i className="fab fa-react"></i>
          <i className="fab fa-ethereum"></i>
          <i className="fab fa-node"></i>
        </div>
        <div className="portfolio-item__links">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/amany9000/dip"
          >
            More
          </a>
        </div>
      </div>
    ),
  },
  {
    name: "DonatETH",
    render: (
      <div className="portfolio-item">
        <div className="portfolio-item__title">DonatETH</div>
        <div className="portfolio-item__desc">
          {truncateText(
            "Donating things is a good thing but keeping the procrastination of our generation in mind most of the things make their way to the dustbin which rather can be used by needy people so to facilitate donating things which in return good karma or token is a win-win situation for all."
          )}
        </div>
        <div className="portfolio-item__icon">
          <i className="fab fa-react"></i>
          <i className="fab fa-ethereum"></i>
          <i className="fab fa-node"></i>
        </div>
        <div className="portfolio-item__links">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/donateth/DonatETH"
          >
            More
          </a>
        </div>
      </div>
    ),
  },
];

export default PortfolioItems;
