import React from "react";
import { Theme } from "./themes";

import { getThemes } from "./themes";

const ThemeSwitcherContext = React.createContext({
  theme: {} as Theme,
});

interface Props {
  children: React.ReactNode;
}

const ThemeSwitcher: React.FC<Props> = (props: Props) => {
  const { children } = props;
  const allThemes = getThemes();
  const firstTimeThemes = allThemes.filter((theme) => theme.firstTime === true);
  const [theme, setTheme] = React.useState(
    firstTimeThemes[Math.floor(Math.random() * firstTimeThemes.length)]
  );

  const getRandomTheme = () => {
    const currentTheme = theme;
    const allThemes = getThemes();
    const themesWithoutCurrentTheme = allThemes.filter(
      (theme) => theme.name !== currentTheme.name
    );
    const randomThemeIndex = Math.floor(
      Math.random() * themesWithoutCurrentTheme.length
    );
    return themesWithoutCurrentTheme[randomThemeIndex];
  };

  const switchTheme = () => {
    const randomTheme = getRandomTheme();
    setTheme(randomTheme);
  };

  const getChildContext = () => ({
    theme: theme,
    switchTheme: switchTheme,
  });

  return (
    <ThemeSwitcherContext.Provider value={getChildContext()}>
      <div className="theme-switcher">
        <style>
          {`
            body {
              background-color: ${theme.bgPrimary};
              body,
              h1,
              h2,
              h3,
              h4,
              h5,
              h6,
              p,
              ol,
              ul {
                color: ${theme.textPrimary};
              }
            }
          `}
        </style>
        {children}
      </div>
    </ThemeSwitcherContext.Provider>
  );
};

interface ThemeSwitcherProps {
  theme: Theme;
  switchTheme: () => void;
}

const useThemeSwitcherContext = () =>
  React.useContext(ThemeSwitcherContext) as ThemeSwitcherProps;

export { useThemeSwitcherContext };

export default ThemeSwitcher;
