import React from "react";
import PropTypes from "prop-types";
import { useThemeSwitcherContext } from "../ThemeSwitcher";

import "./style.scss";

const Footer = () => {
  const {
    theme: { colorPrimary, textPrimary },
  } = useThemeSwitcherContext();
  return (
    <footer>
      <div className="content-grid">
        <div style={{ color: textPrimary }}>
          Made by{" "}
          <a
            href="https://github.com/DivyeshPuri"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: colorPrimary }}
          >
            Divyesh
          </a>{" "}
          <span role="img" aria-label="heart">
            ❤️
          </span>
        </div>
      </div>
    </footer>
  );
};

Footer.contextTypes = {
  theme: PropTypes.any,
  switchTheme: PropTypes.func,
};

export default Footer;
