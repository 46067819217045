import React from "react";
import { toElement as scrollToElement } from "../../utils/scroll";
import { useThemeSwitcherContext } from "../ThemeSwitcher";

import "./style.scss";

interface Props {
  pageSelector: string;
}

const ScrollToPrevious: React.FC<Props> = (props: Props) => {
  const scrollToPrevious = () => {
    const { pageSelector } = props;
    const nextPage = document.querySelector(pageSelector);
    scrollToElement(nextPage);
  };

  const {
    theme: { colorPrimary },
  } = useThemeSwitcherContext();

  return (
    <div className="scroll-to-previous" onClick={scrollToPrevious}>
      <div className="arrow bounce" style={{ color: colorPrimary }}>
        <button className="fas fa-chevron-up fa-2x" />
        <div className="scroll-text">Click Me</div>
      </div>
    </div>
  );
};

export default ScrollToPrevious;
