import React from "react";
import { toElement as scrollToElement } from "../../utils/scroll";
import { useThemeSwitcherContext } from "../ThemeSwitcher";

import "./style.scss";

interface Props {
  pageSelector: string;
}

const ScrollToNext: React.FC<Props> = (props: Props) => {
  const scrollToNext = () => {
    const { pageSelector } = props;
    const nextPage = document.querySelector(pageSelector);
    scrollToElement(nextPage);
  };

  const {
    theme: { colorPrimary },
  } = useThemeSwitcherContext();

  return (
    <div className="scroll-to-next" onClick={scrollToNext}>
      <div className="arrow bounce" style={{ color: colorPrimary }}>
        <div className="scroll-text">Click Me</div>
        <button className="fas fa-chevron-down fa-2x" />
      </div>
    </div>
  );
};

export default ScrollToNext;
