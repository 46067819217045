import React from "react";
import { toTop as scrollToPageTop } from "../../utils/scroll";
import { useThemeSwitcherContext } from "../ThemeSwitcher";
import "./style.scss";

interface Props {}

const ScrollTop: React.FC<Props> = (props: Props) => {
  const [shouldShowScrollTopArrow, setShouldShowScrollTopArrow] =
    React.useState(false);

  const handleScroll = () => {
    if (document.documentElement.getBoundingClientRect().top * -1 > 100)
      setShouldShowScrollTopArrow(true);
    else setShouldShowScrollTopArrow(false);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const {
    theme: { colorPrimary },
  } = useThemeSwitcherContext();

  const hideArrowClass = shouldShowScrollTopArrow ? "" : "hide";
  return (
    <div className="scroll-top" onClick={scrollToPageTop}>
      <div
        className={`arrow ${hideArrowClass}`}
        style={{ color: colorPrimary }}
      >
        <button className="fas fa-angle-double-up fa-2x" />
        <div className="to-top">To Top</div>
      </div>
    </div>
  );
};

export default ScrollTop;
