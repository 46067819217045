import React from "react";
import ThemeSwitcher from "./components/ThemeSwitcher";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./routes/Landing";
import { auth } from "./Firebase";
import { User } from "firebase/auth";

export const AppContext = React.createContext({
  authUser: {} as User | null,
  currentUser: {} as User | null,
});

const App = () => {
  const [localState, setLocalState] = React.useState({
    authUser: {} as User | null,
    currentUser: {} as User | null,
  });

  React.useEffect(() => {
    const authStateChangedSubscriber = auth.onAuthStateChanged(async (user) => {
      setLocalState((prev) => ({ ...prev, authUser: user }));
    });

    return () => authStateChangedSubscriber();
  }, []);

  return (
    <ThemeSwitcher>
      <AppContext.Provider value={localState}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
          </Routes>
        </BrowserRouter>
      </AppContext.Provider>
    </ThemeSwitcher>
  );
};

const useAppContext = () => React.useContext(AppContext);

export { useAppContext };

export default App;
