import React from "react";
import PropTypes from "prop-types";
import SocialIcons from "../../components/SocialIcons";
import Nav from "../../components/Nav";
import ScrollToNext from "../../components/ScrollToNext";
import { useThemeSwitcherContext } from "../../components/ThemeSwitcher";
import TextLoop from "react-text-loop";

import "./style.scss";

const IntroPage = () => {
  const {
    theme: { bgPrimary, colorPrimary },
  } = useThemeSwitcherContext();

  return (
    <div style={{ backgroundColor: bgPrimary }} className="landing-page">
      <Nav />
      <main style={{ color: colorPrimary }}>
        <div className="intro-wrapper">
          <div className="intro-name">Hello, I'm Divyesh!</div>
          <div className="tagline">
            Full Stack{" "}
            <TextLoop fade>
              <span>Web2</span>
              <span>Web3</span>
              <span>React-native</span>
            </TextLoop>{" "}
            Dev | Loves to try new stuff | Hates being bad at anything I do
          </div>
          <SocialIcons />
        </div>
      </main>
      <ScrollToNext pageSelector=".about-page" />
    </div>
  );
};

IntroPage.contextTypes = {
  theme: PropTypes.any,
};

export default IntroPage;
