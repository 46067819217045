import React from "react";
import PropTypes from "prop-types";
import { toElement as scrollToElement } from "../../utils/scroll";
import { useThemeSwitcherContext } from "../ThemeSwitcher";

import "./style.scss";
import LoginModal from "../../modals/LoginModal/LoginModal";

const Nav = () => {
  const [isSticky, setIsSticky] = React.useState(false);
  const [nav, setNav] = React.useState<HTMLElement | null>();
  const [showModal, setShowModal] = React.useState(false);

  const {
    theme: { colorPrimary, bgPrimary, navAlpha },
    switchTheme,
  } = useThemeSwitcherContext();

  const stickyClass = isSticky ? "sticky" : "";
  const stickyStyles = isSticky
    ? { backgroundColor: navAlpha, color: colorPrimary }
    : { backgroundColor: bgPrimary, color: colorPrimary };

  const handleScroll = React.useCallback(() => {
    window.pageYOffset > (nav?.offsetTop as number)
      ? setIsSticky(true)
      : setIsSticky(false);
  }, [nav?.offsetTop]);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const scrollToPage = (pageSelector: string) => {
    const nextPage = document.querySelector(pageSelector);
    scrollToElement(nextPage);
  };

  return (
    <nav
      className={stickyClass}
      ref={(elem) => setNav(elem)}
      style={stickyStyles}
    >
      <div className="magic-wand bounce-xy" onClick={switchTheme}>
        <button className="fas fa-magic fa-lg" />
        <div className="magic-text">Paint Me 🥵</div>
      </div>
      <style>
        {`
          .menu__item:hover {
            border-bottom: 2px solid ${colorPrimary};
          }
        `}
      </style>
      <div className="menu">
        <div
          className="menu__item active"
          onClick={(e) => scrollToPage(".about-page")}
        >
          About
        </div>
        <div
          className="menu__item"
          onClick={(e) => scrollToPage(".portfolio-page")}
        >
          Portfolio
        </div>
        <div
          className="menu__item"
          onClick={(e) => scrollToPage(".experience-page")}
        >
          Experience
        </div>
        {/* <div className="menu__item" onClick={(e) => setShowModal(true)}>
          Create
        </div> */}
      </div>
      <LoginModal isOpen={showModal} onClickClose={() => setShowModal(false)} />
    </nav>
  );
};

Nav.contextTypes = {
  theme: PropTypes.any,
  switchTheme: PropTypes.func,
};

export default Nav;
