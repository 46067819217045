/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useThemeSwitcherContext } from "../ThemeSwitcher";
import "./style.scss";

interface Props {
  render: React.ReactElement;
}

function PortfolioItem(props: Props) {
  const {
    theme: { colorPrimary, textAlternate },
  } = useThemeSwitcherContext();

  if (props.render) return props.render;
  else
    return (
      <div
        className="portfolio-item"
        style={{ backgroundColor: colorPrimary, color: textAlternate }}
      >
        <div className="portfolio-item__title">Badass</div>

        <div className="portfolio-item__desc">
          I do badass things and kickass everyday! I do badass things and
          kickass everyday! I do badass things and kickass everyday!
        </div>
        <div className="portfolio-item__icon">
          <i className="fab fa-js" />
          <i className="fab fa-react" />
          <i className="fab fa-html5" />
        </div>
        <div className="portfolio-item__links">
          <a href="#">Code</a>
          <a href="#">Blog</a>
        </div>
      </div>
    );
}

export default PortfolioItem;
