import React from "react";
import Modal from "../../components/Modal/Modal";

interface Props {
  isOpen: boolean;
  onClickClose: () => void;
}

const LoginModal: React.FC<Props> = (props: Props) => {
  return (
    <Modal isOpen={props.isOpen} onClickClose={props.onClickClose}>
      <>
        <h2>Hello</h2>
        <button onClick={props.onClickClose}>close</button>
        <div>I am a modal</div>
        <form>
          <input />
          <button>tab navigation</button>
          <button>stays</button>
          <button>inside</button>
          <button>the modal</button>
        </form>
      </>
    </Modal>
  );
};

export default LoginModal;
