import React from "react";
import PropTypes from "prop-types";
import { useThemeSwitcherContext } from "../ThemeSwitcher";

import "./style.scss";

const SocialIcons = () => {
  const {
    theme: { colorPrimary },
  } = useThemeSwitcherContext();

  return (
    <div className="social-icons animate-icons">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://github.com/DivyeshPuri"
        style={{ color: colorPrimary }}
      >
        <i className="fab fa-github"></i>
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.facebook.com/DivyeshPuri"
        style={{ color: colorPrimary }}
      >
        <i className="fab fa-facebook"></i>
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.linkedin.com/in/divyeshpuri/"
        style={{ color: colorPrimary }}
      >
        <i className="fab fa-linkedin"></i>
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://medium.com/@d1vyesh"
        style={{ color: colorPrimary }}
      >
        <i className="fab fa-medium"></i>
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://stackoverflow.com/users/7429194/divyesh-puri"
        style={{ color: colorPrimary }}
      >
        <i className="fab fa-stack-overflow"></i>
      </a>
    </div>
  );
};

SocialIcons.contextTypes = {
  theme: PropTypes.any,
};

export default SocialIcons;
