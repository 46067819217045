import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import { useThemeSwitcherContext } from "../ThemeSwitcher";
import { Experience } from "../../pages/ExperiencePage/ExperiencePageUtil";

interface Props {
  item: Experience;
}

const PastExperienceItem: React.FC<Props> = (props: Props) => {
  const {
    theme: { textPrimary },
  } = useThemeSwitcherContext();

  const { company, jobTitle, duration, tasksDone } = props.item;

  return (
    <div className="pastexperience-item" style={{ color: textPrimary }}>
      <div className="pastexperience-item__jobTitle">{jobTitle}</div>
      <div className="pastexperience-item__jobDescription">
        <span className="pastexperience-item__company">{company} </span>
        <span className="pastexperience-item__seperator">&#183;</span>
        <span className="pastexperience-item__jobDuration">{duration}</span>
      </div>
      <div className="pastexperience-tasksWrapper">
        <ul className="pastexperience-item__tasksDone">
          {tasksDone.map((taskDone: string, key: number) => (
            <li className="pastexperience-item__listItem" key={key}>
              <span>{taskDone}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

PastExperienceItem.contextTypes = {
  theme: PropTypes.any,
};

export default PastExperienceItem;
