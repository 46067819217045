import React from "react";
import PropTypes from "prop-types";
import { useThemeSwitcherContext } from "../ThemeSwitcher";

import "./style.scss";

const ParallaxBackground = () => {
  const {
    theme: { colorPrimary },
  } = useThemeSwitcherContext();

  return (
    <div className="parallax" style={{ color: colorPrimary }}>
      <div id="stars1" />
      <div id="stars2" />
      <div id="stars3" />
    </div>
  );
};

ParallaxBackground.contextTypes = {
  theme: PropTypes.any,
};

export default ParallaxBackground;
