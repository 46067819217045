import { Experience } from "./ExperiencePageUtil";

const pastexperience = [
  {
    company: "Aspire",
    jobTitle: "Full stack Developer",
    duration: "April 2021 -- Present",
    tasksDone: [
      "Working on creating something great, lead project from its init to deployment and made sure everything worked with utmost UX and bugfree.",
      "Lead Web3 initiative inside company and developed multiple smart contracts and architecture pattern to facilitate the vision and build neccessary features.",
      "Developed the app on both web using react and ios development using react-native with backend in nodejs",
    ],
  },
  {
    company: "Shezh",
    jobTitle: "Co-Founder",
    duration: "Jan 2020 -- March 2021",
    tasksDone: [
      "Built a product that placed incentives at right place to promote better habits towards waste management.",
      "Built a product that helped companies working in waste management increase their efficiency and alleviate problems faced by them during the Covid situation.",
      "Designed the system to make it scalable and reliable even under heavy load.",
    ],
  },
  {
    company: "Quikr India",
    jobTitle: "Software Developer",
    duration: "May 2019 -- Jul 2019",
    tasksDone: [
      "Built a product single-handedly to help customer support post Ads on Quikr’s platform without having to fill in any information.",
      "Scraped and stored data from the different platform and mapped them to Quikr’s database for one-click posting.",
    ],
  },
  {
    company: "STEMpedia",
    jobTitle: "Software Developer",
    duration: "Jun 2018 -- Aug 2018",
    tasksDone: [
      "Developed the next-generation integration platform to help kids learn to code /robotics using Scratch 3.0.",
      "Collaborated with other developers to identify and alleviate the number of bugs in the software",
    ],
  },
  {
    company: "Quotemykaam Business Services",
    jobTitle: "Frontend Developer",
    duration: "Jun 2018 -- Aug 2018",
    tasksDone: [
      "Designed and updated layouts to meet usability and performance requirements.",
      "Developed designs to meet specific requirements such as quick-loading sites with particular layouts and made them offline compatible.",
      "Produced websites compatible with multiple browsers.",
    ],
  },
] as Experience[];

export default pastexperience;
